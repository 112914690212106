<template>
    <!-- Footer -->
    <MDBFooter :text="['center', 'lg-start', 'muted']" class="footer-backgroundFont">
        <!-- Section: Social media -->
        <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
            <!-- Left -->
            <div class="me-5 d-none d-lg-block">
                <span>Contáctanos a través de nuestras redes sociales:</span>
            </div>
            <!-- Left -->

            <!-- Right -->
            <div>
                <a href="" class="me-4 text-reset">
                    <MDBIcon class="link-secondary" iconStyle="fab" icon="facebook-f"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                    <MDBIcon class="link-secondary" iconStyle="fab" icon="twitter"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                    <MDBIcon class="link-secondary" iconStyle="fab" icon="google"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                    <MDBIcon class="link-secondary" iconStyle="fab" icon="instagram"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                    <MDBIcon class="link-secondary" iconStyle="fab" icon="linkedin"></MDBIcon>
                </a>
                <a href="" class="me-4 text-reset">
                    <MDBIcon class="link-secondary" iconStyle="fab" icon="github"></MDBIcon>
                </a>
            </div>
            <!-- Right -->
        </section>
        <!-- Section: Social media -->

        <!-- Section: Links  -->
        <section class="">
            <MDBContainer class="text-center text-md-start mt-5">
                <!-- Grid row -->
                <MDBRow class="mt-3">
                    <!-- Grid column -->
                    <MDBCol md="3" lg="4" xl="3" class="mx-auto mb-4">
                        <!-- Content -->
                        <h6 class="text-uppercase fw-bold mb-4">
                            <i class="fas fa-gem me-3 link-secondary"></i>SEW
                        </h6>
                        <p>
                            Here you can use rows and columns to organize your footer
                            content. Lorem ipsum dolor sit amet, consectetur
                            adipisicing elit.
                        </p>
                    </MDBCol>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <MDBCol md="2" lg="2" xl="2" class="mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="text-uppercase fw-bold mb-4">Products</h6>
                        <p>
                            <a href="#!" class="text-reset">Angular</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset">React</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset">Vue</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset">Laravel</a>
                        </p>
                    </MDBCol>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <MDBCol md="3" lg="2" xl="2" class="mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="text-uppercase fw-bold mb-4">Useful links</h6>
                        <p>
                            <a href="#!" class="text-reset">Pricing</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset">Settings</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset">Orders</a>
                        </p>
                        <p>
                            <a href="#!" class="text-reset">Help</a>
                        </p>
                    </MDBCol>
                    <!-- Grid column -->

                    <!-- Grid column -->
                    <MDBCol md="4" lg="3" xl="3" class="mx-auto mb-md-0 mb-4">
                        <!-- Links -->
                        <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                        <p>
                            <i class="fas fa-home me-3 link-secondary"></i> Xalapa,
                            Veracruz, MX
                        </p>
                        <p>
                            <i class="fas fa-envelope me-3 link-secondary"></i>
                            sew@gmail.com
                        </p>
                        <p>
                            <MDBIcon icon="phone" class="me-3 link-secondary" />
                            + 01 234 567 88
                        </p>                        
                    </MDBCol>
                    <!-- Grid column -->
                </MDBRow>
                <!-- Grid row -->
            </MDBContainer>
        </section>
        <!-- Section: Links  -->

        <!-- Copyright -->
        <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05)">
            © 2021 Copyright:
            <a class="text-reset fw-bold" href="https://mdbootstrap.com/">MDBootstrap.com</a>
        </div>
        <!-- Copyright -->
    </MDBFooter>
    <!-- Footer -->
</template>

<style scoped src="@/wwwroot/css/footer.css"></style>
<style scoped src="@/wwwroot/css/sharedStyles.css"></style>
<script src="@/wwwroot/js/footer.js"></script>